import React from 'react'
import { usePrivateRoute } from 'src/utils/auth'
import { Router, RouteComponentProps } from '@reach/router'
import Layout from 'src/components/layout/layout'
import SEO from 'src/components/seo'
import BoxScore from 'src/components/box-score/box-score'

const PlayerProfilePage = () => {
  usePrivateRoute()
  return (
    <Router basepath="/players">
      <PlayerProfileRoute path="/:playerId/boxScore/:id" />
    </Router>
  )
}

interface PlayerProfileRouteProps extends RouteComponentProps {
  id?: string
  playerId?: string 
}

const PlayerProfileRoute: React.FC<PlayerProfileRouteProps> = ({ id, playerId }) => {
  return (
    <Layout>
      <SEO title="Box Score" />
      <BoxScore id={id} playerId={playerId}/>
    </Layout>
  )
}

export default PlayerProfilePage
